<script setup lang="ts">
import type { Member } from '@respell/database';
import { z } from 'zod';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();

const schema = z.object({
  teamId: z.string().min(1),
  newMembers: z
    .array(z.string())
    .min(1, { message: 'Must select at least 1 workspace member.' }),
});

type Schema = z.output<typeof schema>;

const workspaceStore = useWorkspaceStore();
const { createMembers } = workspaceStore;
const { teamMembers, workspaceMembers, workspace } =
  storeToRefs(workspaceStore);

await useAsyncCache('workspaceMembers/' + workspace.value?.id, () =>
  workspaceStore.loadWorkspaceMembers(),
);

const state = reactive({
  newMembers: [],
});

const addableMembers = computed(() => {
  const workspaceUserIds = workspaceMembers.value?.map(
    (member: Member) => member.userId,
  );
  const teamUserIds = teamMembers.value?.map((member: Member) => member.userId);
  const difference = useArrayDifference(workspaceUserIds, teamUserIds).value;
  return difference.map((userId) => {
    return workspaceMembers.value?.find((member) => member.userId === userId);
  });
});

async function onSubmit() {
  await createMembers(state.newMembers.map((member) => member.id));
  modal.close();
}

const userOptions = computed(() => {
  return addableMembers.value.map((addableMember: Member) => {
    return {
      id: addableMember.userId,
      label: addableMember.user?.profile?.fullName,
    };
  });
});
</script>
<template>
  <UModal>
    <UForm :schema="schema" :state="state" class="w-full space-y-4">
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Add members</div>
        </template>

        <div>
          <p class="text-sm mb-2 dimmed">
            Members can add others, create spells and change settings for this
            Team.
          </p>
          <UFormGroup name="newMembers" required>
            <USelectMenu
              v-model="state.newMembers"
              :options="userOptions"
              searchable
              multiple
              placeholder="Select members from this workspace"
              searchable-placeholder="Search for members"
            />
          </UFormGroup>
          <div class="my-4">
            <div v-for="member in teamMembers" :key="member.id" class="py-1">
              <UAvatar :alt="member.user.profile.fullName" size="sm" />
              <span class="font-bold ml-2 mr-2">{{
                member.user.profile.fullName
              }}</span>
              <span class="dimmed">{{ member.user.email }}</span>
            </div>
          </div>
        </div>

        <template #footer>
          <UButton size="xl" @click="onSubmit">Submit</UButton>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
